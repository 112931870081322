import {useRoutes} from "react-router-dom";
import routesConfig from "routes/routeConfig";

// Router component
export default function Router() {
    return useRoutes(
        routesConfig.map((route) => ({
            path: route.path,
            element: route.element,
            children: route.children?.map((child) => ({
                path: child.path,
                element: child.element,
                index: child.index,
                children: child.children?.map((subChild) => ({
                    path: subChild.path,
                    element: subChild.element,
                    index: subChild.index,
                })),
            })),
        }))
    );
}
