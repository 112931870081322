import PropTypes from "prop-types";
// @mui
import {styled} from "@mui/material/styles";
import {List, Box, ListSubheader} from "@mui/material";
//
import {NavListRoot} from "./NavList";
// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
    <ListSubheader disableSticky disableGutters {...props} />
))(({theme}) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------
NavSectionVertical.propTypes = {
    isCollapse: PropTypes.bool,
    navConfig: PropTypes.any,
};
export default function NavSectionVertical({
                                               navConfig,
                                               isCollapse = false,
                                               ...other
                                           }) {
    return (
        <Box {...other}>
            {navConfig.length === 2 ? (
                <>
                    {navConfig.map((group) => (
                        <List key={group.subheader} disablePadding sx={{px: 2}}>
                            <ListSubheaderStyle
                                sx={{
                                    ...(isCollapse && {
                                        opacity: 0,
                                    }),
                                }}
                            >
                                {group.subheader}
                            </ListSubheaderStyle>
                            {group.items.map((list) => (
                                <NavListRoot
                                    key={list.title}
                                    list={list}
                                    isCollapse={isCollapse}
                                />
                            ))}
                        </List>
                    ))}
                </>
            ) : (
                <List key={navConfig[0].subheader} disablePadding sx={{px: 2}}>
                    <ListSubheaderStyle
                        sx={{
                            ...(isCollapse && {
                                opacity: 0,
                            }),
                        }}
                    >
                        {navConfig[0].subheader}
                    </ListSubheaderStyle>
                    {navConfig[0].items.map((list) => (
                        <NavListRoot key={list.title} list={list} isCollapse={isCollapse}/>
                    ))}
                </List>
            )}
        </Box>
    );
}
