import {createContext, useEffect, useReducer} from "react";
import PropTypes from "prop-types";
// utils
import axios from "axios";
import {setSession} from "../utils/jwt";
import {AUTH_API, AUTH_SERVICE_API_KEY, HOST_API} from "../config";
import {enqueueSnackbar} from "notistack";
import convertUserModule from "utils/convertUserModule";

// ----------------------------------------------------------------------

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const {isAuthenticated, user} = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    },
    LOGIN: (state, action) => {
        const {user} = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
    REGISTER: (state, action) => {
        const {user} = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
    ...initialState,
    method: "jwt",
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node,
};

function AuthProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = window.localStorage.getItem("accessToken");
                // isValidToken(accessToken)
                // decodeToken(accessToken)
                if (accessToken /*&& isValidToken(accessToken)*/) {
                    setSession(accessToken);

                    axios
                        .get(HOST_API + "users/dashboard/app", {
                            headers: {
                                Authorization: accessToken,
                                "Api-Key": AUTH_SERVICE_API_KEY,
                            },
                        })
                        .then(({data}) => {
                            const {status} = data;
                            if (status === 1) {
                                const user = data.data.user;
                                user.modules = convertUserModule(data.data.user.PannelModules)

                                // enqueueSnackbar(message, {variant: 'success'})
                                dispatch({
                                    type: "INITIALIZE",
                                    payload: {
                                        isAuthenticated: true,
                                        user,
                                    },
                                });
                            }
                        })
                        .catch((e) => {
                            // enqueueSnackbar(e.response.data.error, { variant: "error" });
                            dispatch({
                                type: "INITIALIZE",
                                payload: {
                                    isAuthenticated: false,
                                    user: null,
                                },
                            });
                        });

                    // dispatch({
                    //     type: 'INITIALIZE',
                    //     payload: {
                    //         isAuthenticated: true,
                    //         user,
                    //     },
                    // });
                } else {
                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };
        initialize();
    }, []);

    const login = async (formData) => {
        let myToken = "";
        await axios
            .post(AUTH_API + "v1/account/login", formData, {
                headers: {
                    "Api-Key": AUTH_SERVICE_API_KEY,
                },
            })
            .then(({data}) => {
                const token = "Bearer " + data.data.token;
                if (data.status) {
                    setSession(token);
                    myToken = token;
                }
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.message, {variant: "error"});
            });

        if (myToken) {
            await axios
                .get(HOST_API + "users/dashboard/app", {
                    headers: {
                        Authorization: myToken,
                        "Api-Key": AUTH_SERVICE_API_KEY,
                    },
                })
                .then(({data}) => {
                    const {status} = data;
                    if (status === 1) {
                        const user = data.data.user;
                        user.modules = convertUserModule(data.data.user.PannelModules)
                        // enqueueSnackbar(message, { variant: "success" });
                        dispatch({
                            type: "LOGIN",
                            payload: {
                                user,
                            },
                        });
                    }
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error, {variant: "error"});
                });
        }
    };

    const register = async (email, password, firstName, lastName) => {
        const response = await axios.post("/api/account/register", {
            email,
            password,
            firstName,
            lastName,
        });
        const {accessToken, user} = response.data;

        window.localStorage.setItem("accessToken", accessToken);
        dispatch({
            type: "REGISTER",
            payload: {
                user,
            },
        });
    };

    const logout = async () => {
        setSession(null);
        dispatch({type: "LOGOUT"});
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "jwt",
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export {AuthContext, AuthProvider};
