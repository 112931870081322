export default function convertUserModule(items) {
    const itemMap = new Map();
    const rootItems = [];

    // Create a map of all items
    items.forEach(item => {
        // Initialize each item differently based on whether it has a parent
        if (item.parent_id) {
            // Child items do not have icons
            itemMap.set(item.id, {
                title: item.name,
                // path: generatePath(item.slug)
                path: '/' + (item.slug),
                permissions: item.permissions
            });
        } else {
            // Root items include icons
            itemMap.set(item.id, {
                title: item.name,
                // path: generatePath(item.slug),
                path: '/' + (item.slug),
                icon: generateIcon(item.name),
                permissions: item.permissions
            });
        }
    });

    // Assign children to their respective parent items
    items.forEach(item => {
        if (item.parent_id) {
            const parent = itemMap.get(item.parent_id.id);
            if (parent) {
                // Ensure the parent item has a children array
                if (!parent.children) {
                    parent.children = [];
                }
                parent.children.push(itemMap.get(item.id));
            }
        } else {
            // Add root items to the root list
            rootItems.push(itemMap.get(item.id));
        }
    });

    return rootItems;
}

function generateIcon(name) {
    // Map names to icons
    const icons = {
        "کاربران": "/icons/ic_users.svg",
        // Add other specific mappings here
    };
    return "_"
    // return {
    //     key: null,
    //     ref: null,
    //     props: {
    //         src: icons[name] || "/icons/default.svg",
    //         sx: {width: 1, height: 1}
    //     },
    //     _owner: null,
    //     _store: {}
    // };
}
